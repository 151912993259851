
  import { GForm } from '@/components/forms/GForm'
  import { Component, Watch } from 'vue-property-decorator'
  import TodayActivityList from '@/components/person/leadActivity/TodayActivityList.vue'
  import dayjs from 'dayjs'
  import { CalendarEvent } from 'vuetify'
  import { LeadActivity } from '@/entities/crm'
  import TaskDetail from '@/components/forms/fields/agenda/TaskDetail.vue'

@Component({
  components: {
    TodayActivityList,
    TaskDetail,
  },
  filters: {
    dateFilter: str => {
      return dayjs(str).format('DD/MM/YYYY - HH:mm')
    },
    hourFilter: str => {
      return dayjs(str).format('HH:mm')
    },
  },
})
  export default class ScheduleView extends GForm {
  categories = ['Día', 'Semana', 'Mes']
  type = ['day', 'week', 'month']
  category = 2;
  leadActivityData: LeadActivity[] = [];
  events: CalendarEvent[] = []
  utcOffset = dayjs().utcOffset()
  selectedEvent: CalendarEvent = {}
  displayEvent = false
  focus = dayjs().format('YYYY-MM-DD')
  timeStart = ''
  timeInterval = 14

  // Methods
  prev () {
    this.$refs.calendar.prev()
  }

  next () {
    this.$refs.calendar.next()
  }

  async showEvent ({ nativeEvent, event }) {
    this.selectedEvent = event
    this.displayEvent = true
  }

  showDay ({ date }) {
    this.focus = date
    this.category = 0
  }

  closeEventCard () {
    this.displayEvent = false
  }

  async getLead (leadId) {
    return this.fetchData({
      query: { name: 'fetch', model: 'Lead', params: { id: leadId } },
    })
  }

  async getProcess () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'lead_activity' } },
    })

    const config = process?.[0]?.config

    this.timeStart =
      dayjs(`${dayjs().format('YYYY-MM-DD')} ${config.timeStart}`)
        .subtract(1, 'hour')
        .format('HH:mm')
  }

  mounted () {
    this.getProcess()
  }

  // Getters
  get currentType () {
    return this.type[this.category]
  }

  get currentMonth () {
    if (this.$refs.calendar) {
      return this.$refs.calendar.title
    }
    return ''
  }

  get displayMonth () {
    const monthNumber = dayjs(this.focus).format('MM')
    const months = {
      '01': 'Enero',
      '02': 'Febrero',
      '03': 'Marzo',
      '04': 'Abril',
      '05': 'Mayo',
      '06': 'Junio',
      '07': 'Julio',
      '08': 'Agosto',
      '09': 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre',
    }
    return `${months[monthNumber]} ${dayjs(this.focus).format('YYYY')}`
  }

  // Watchers
  @Watch('idEmployee')
  async onIdEmployeeChanged () {
    if (this.idEmployee) {
      const data = await this.fetchData({
        query: { name: 'find', model: 'LeadActivity' },
        filter: {
          id_closing_reason: { _is_null: true },
          lead: {
            executive: { id: { _eq: this.idEmployee } },
            id_closing_reason: { _is_null: true },
          },
        },
        force: true,
      })
      this.leadActivityData = await data
    }
  }

  @Watch('leadActivityData')
  async onLeadActivityDataChanged () {
    this.leadActivityData.forEach(async activity => {
      const newEvent: Record<string, any> = {
        start: activity.schedulingDate
          .add(this.utcOffset, 'minute')
          .format('YYYY-MM-DD HH:mm'),
        end: activity.schedulingDate
          .add(this.utcOffset, 'minute')
          .add(activity.duration)
          .format('YYYY-MM-DD HH:mm'),
        name: activity.type.description,
        activity,
        lead: await this.getLead(activity.lead.id),
        color: activity.type.color,
        icon: activity.type.icon,
        notes: activity.notes,
      }
      this.events.push(newEvent)
    })

    this.events.sort((firstEvent, secondEvent) => {
      if (dayjs(firstEvent.start) < dayjs(secondEvent.start)) return 1
      return -1
    })
  }

  @Watch('category')
  updateCategory (newValue) {
    this.category = newValue
  }
  }
